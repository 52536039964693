import React, { ReactElement } from "react";
import styled from "styled-components";

const CardContentContainer = styled.article`
  position: relative;
  display: flex;
  margin: 10px;
`;

interface CardContentProps {
  key?: string;
  children: ReactElement | ReactElement[];
  className?: string;
}

const CardContent = ({
  key,
  children,
  className,
}: CardContentProps): ReactElement => (
  <CardContentContainer className={className} key={key}>
    {children}
  </CardContentContainer>
);

export default CardContent;
