import React from "react";
import styled from "styled-components";

const StyledH1 = styled.h1`
  font-family: "Bebas Neue";
  font-size: 3.6rem;
  line-height: 1.1;
`;

const Title = ({ children }) => {
  return <StyledH1>{children}</StyledH1>;
};

export default Title;
