import React, { ReactElement } from "react";
import styled from "styled-components";

const CardContainer = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
`;

interface CardProps {
  key?: string;
  children: ReactElement | ReactElement[];
}

const Card = ({ key, children }: CardProps): ReactElement => (
  <CardContainer key={key}>{children}</CardContainer>
);

export default Card;
