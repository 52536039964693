import React, { ReactElement } from "react";
import styled from "styled-components";

const CardThumbnailContainer = styled.div`
  position: absolute;
  top: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

interface CardThumbnailProps {
  key?: string;
  children: ReactElement | ReactElement[];
}

const CardThumbnail = ({ key, children }: CardThumbnailProps): ReactElement => (
  <CardThumbnailContainer key={key}>{children}</CardThumbnailContainer>
);

export default CardThumbnail;
