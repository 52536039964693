import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Card from "../components/card/card";
import CardThumbnail from "../components/card/card-thumbnail";
import CardMedia from "../components/card/card-media";
import CardContent from "../components/card/card-content";
import SEO from "../components/seo/seo";
import Title from "../components/title/title";
import SubTitle from "../components/sub-title/sub-title";

const GalleryContainer = styled.main`
  margin-top: 40px;
`;

const Gallery = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 40px;
  margin-top: 40px;
  margin-bottom: 80px;
`;

const StyledCardContent = styled(CardContent)`
  justify-content: center;
`;

const StyledH2 = styled.h2`
  font-family: "Bebas Neue";
  font-size: 2rem;
  font-weight: 500;
  margin: 8px 0;
`;

const GalleryPage = ({ data }) => {
  const { title, description, photos } = data.prismicGallerypage.data;
  const mappedPhotos = photos
    .map((photo) => {
      const { uid } = photo.photo.document;
      const { title, image } = photo.photo.document.data;
      return {
        uid,
        title,
        image: getImage(image.localFile),
      };
    })
    .reverse();
  return (
    <Layout>
      <SEO
        title={`${title} - Ben Pearey`}
        description={description?.text}
      ></SEO>
      <GalleryContainer>
        <Title>{title}</Title>
        <SubTitle>{description.text}</SubTitle>
        <Gallery>
          {mappedPhotos.map(({ uid, title, image }) => (
            <Card key={uid}>
              <Link to={`/photo/${uid}`}>
                <CardMedia>
                  <CardThumbnail>
                    <GatsbyImage image={image} alt={title} />
                  </CardThumbnail>
                </CardMedia>
                <StyledCardContent>
                  <StyledH2>{title}</StyledH2>
                </StyledCardContent>
              </Link>
            </Card>
          ))}
        </Gallery>
      </GalleryContainer>
    </Layout>
  );
};

export default GalleryPage;

export const query = graphql`
  query GalleryQuery {
    prismicGallerypage {
      data {
        title
        description {
          text
        }
        photos {
          photo {
            document {
              ... on PrismicPhoto {
                data {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 85
                          height: 590
                          width: 590
                          placeholder: BLURRED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                  title
                }
                uid
              }
            }
          }
        }
      }
    }
  }
`;
