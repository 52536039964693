import React from "react";
import styled from "styled-components";

const StyledH2 = styled.h2`
  color: #4a4a4a;
  font-size: 1rem;
  margin: 8px 0;
  line-height: 1.4rem;
`;

const SubTitle = ({ children }) => {
  return <StyledH2>{children}</StyledH2>;
};

export default SubTitle;
