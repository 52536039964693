import React, { ReactElement } from "react";
import styled from "styled-components";

const CardMediaContainer = styled.article`
  padding-top: 100%;
  position: relative;
  display: flex;
`;

interface CardMediaProps {
  key?: string;
  children: ReactElement | ReactElement[];
}

const CardMedia = ({ key, children }: CardMediaProps): ReactElement => (
  <CardMediaContainer key={key}>{children}</CardMediaContainer>
);

export default CardMedia;
